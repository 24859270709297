<template>
    <header class="page-title">
        <h1><i class="bi bi-gear"></i> 会社設定</h1>
    </header>

    <form @submit.prevent="update">

    <section class="section">
        <h2 class="section-title">基本設定</h2>
        <div class="row mb-3 align-items-end">
            <div class="col-6">
                <label class="form-label">会社名 (略称)</label>
                <form-input v-model="company.company_name" required></form-input>
            </div>
            <div class="col-6">
                <label class="form-label">決算月</label>
                <div class="input-group">
                    <form-input-number v-model="company.settlement_month" required min="1" max="12"></form-input-number>
                    <div class="input-group-text">月</div>
                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <h2 class="section-title">帳票設定</h2>
        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label">会社名 (正式名称)</label>
                <form-input v-model="company.official_name"></form-input>
            </div>
            <div class="col-6">
                <label class="form-label">電話番号</label>
                <form-input v-model="company.phone"></form-input>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <label class="form-label">住所1</label>
                <form-input v-model="company.address_1"></form-input>
            </div>
            <div class="col-12">
                <label class="form-label">住所2</label>
                <form-input v-model="company.address_2"></form-input>
            </div>
        </div>
    </section>

    <section class="section">
        <h2 class="section-title">レポート用設定</h2>
        <div class="row mb-3 align-items-end">
            <div class="col-6">
                <label class="form-label">安全粗利率</label>
                <div class="input-group">
                    <form-input-number v-model="company.profit_rate_safe" required min="0" max="100"></form-input-number>
                    <div class="input-group-text">％以上</div>
                </div>
            </div>
            <div class="col-6">
                <label class="form-label">危険粗利率</label>
                <div class="input-group">
                    <form-input-number v-model="company.profit_rate_danger" required min="0" max="100"></form-input-number>
                    <div class="input-group-text">％以下</div>
                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <button class="btn btn-info btn-lg">保存</button>
    </section>

    </form>
</template>

<script>
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import Company from "@/models/entities/company";

export default {
    name: 'PageSystemCompany',
    components: {
        FormInput,
        FormInputNumber,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            company: new Company()
        }
    },
    mounted() {
        this.$http.get('/system/company')
        .then((response) => {
            this.company = new Company(response.data);
            this.loading = false;
        })
    },
    methods: {
        update() {
            this.startScreenLoading();
            this.$http.put('/system/company', this.company)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>
